// Product.js
import React, { useState, useEffect } from 'react';
import Modal from './Modal';


import { v4 as uuidv4 } from 'uuid';
import './product.css';

const Product = ({ product, onAddToCart, updateCartInfo }) => {
  const { id, productName, beskrivelse, price, image, options, kampanje, pakke } = product;
  const imagePath = `https://www.bbelektroapi.shop/bilder/${image}`;




  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
    // eslint-disable-next-line
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(price);
  // eslint-disable-next-line
  const [showExplanation, setShowExplanation] = useState({});
  const [selectedExplanation, setSelectedExplanation] = useState(null);
  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);

  const [isHoverDisabled, setIsHoverDisabled] = useState(false);
  useEffect(() => {
    const totalWithOptions = Object.keys(selectedOptions).reduce((acc, optionId) => {
      const selectedAlternative = selectedOptions[optionId];
      if (selectedAlternative) {
        const alternative = options[optionId]?.alternatives.find(
          (alt) => alt.alternative === selectedAlternative
        );
        if (alternative) {
          acc += parseInt(alternative.price);
        }
      }
      return acc;
    }, parseInt(price));

    const totalPrice = totalWithOptions * selectedQuantity;
    setTotalPrice(totalPrice);
  }, [selectedOptions, selectedQuantity, price, options]);

  const handleModalClose = () => {

 
    setIsModalOpen(false);
    setIsHoverDisabled(false); // Enable hover effect when modal is closed
  };
  
  const handleCustomizeClick = () => {
    setIsHoverDisabled(true); // Disable hover effect when modal is open
    setIsModalOpen(true);
  };

  const handleAddToCartClick = () => {
    // Sjekk om noen av selectorene har ugyldig verdi ("0")
    const hasInvalidOption = Object.keys(selectedOptions).some(
      (optionId) => selectedOptions[optionId] === '0'
    );
  
    // Sjekk også om quantity er gyldig
    const isQuantityValid = parseInt(selectedQuantity) > 0;
  
    // Sjekk om alle selectorene har gyldige verdier
    const allOptionsSelected = Object.keys(selectedOptions).every(
      (optionId) => selectedOptions[optionId] && selectedOptions[optionId] !== '0'
    );
  
    if (hasInvalidOption || !isQuantityValid || !allOptionsSelected) {
      console.error('Velg gyldige alternativer før du legger til i handlekurven.');
  
      // Vis feilmelding til brukeren (du kan tilpasse måten du viser feil på)
      alert('Velg gyldige alternativer før du legger til i handlekurven.');
      return;
    }
  
    // Sjekk om brukeren har valgt et alternativ i alle alternativer
    const allOptionsAlternativesSelected = Object.keys(options).every(
      (optionId) => selectedOptions[optionId] && selectedOptions[optionId] !== '0'
    );
  
    if (!allOptionsAlternativesSelected) {
      console.error('Velg gyldige alternativer før du legger til i handlekurven.');
  
      // Vis feilmelding til brukeren (du kan tilpasse måten du viser feil på)
      alert('Velg gyldige alternativer før du legger til i handlekurven.');
      return;
    }
  
    const totalWithOptions = Object.keys(selectedOptions).reduce((acc, optionId) => {
      const selectedAlternative = selectedOptions[optionId];
      const alternative = options[optionId].alternatives.find(
        (alt) => alt.alternative === selectedAlternative
      );
      acc += parseInt(alternative.price);
      return acc;
    }, parseInt(price));
  
    const totalPrice = totalWithOptions * parseInt(selectedQuantity);
  
    if (totalPrice > 0) {
      const cartItem = {
        id: id || uuidv4(), // Use a generated UUID if id is null
        productName,
        beskrivelse,
        totalPrice,
        totalWithOptions,
        selectedOptions,
        selectedQuantity,
        image: imagePath,
        // Include option details in the cart item
        optionDetails: Object.keys(selectedOptions).map((optionId) => {
          const selectedAlternative = selectedOptions[optionId];
          const alternative = options[optionId].alternatives.find(
            (alt) => alt.alternative === selectedAlternative
          );
          return {
            optionId,
            question: options[optionId].question,
            answer: selectedAlternative,
            price: alternative ? parseInt(alternative.price) : 0,
          };
        }),
      };
  
      // Use the onAddToCart function to add the item to the cart
      onAddToCart(cartItem);
  
      // Retrieve the existing cart from localStorage
      const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
  
      // Update the cart with the new item
      const updatedCart = [...existingCart, cartItem];
  
      // Save the updated cart to localStorage
      localStorage.setItem('cart', JSON.stringify(updatedCart));
  
      console.log(`Lagt til i handlekurven: ${selectedQuantity} ${productName} med alternativer:`, selectedOptions);
      console.log(`Totalpris: ${totalPrice} nok`);
  
      // Log the updated cart
      console.log('Updated Cart:', updatedCart);
  
      setTotalPrice(totalPrice);
      setIsModalOpen(false);
      setIsHoverDisabled(false); // Enable hover effect when modal is closed
      
    } else {
      console.error('Velg gyldige alternativer før du legger til i handlekurven.');
  
      // Vis feilmelding til brukeren (du kan tilpasse måten du viser feil på)
      alert('Velg gyldige alternativer før du legger til i handlekurven.');
    }
  };
  


  const toggleExplanation = (optionId) => {
    console.log('Before toggleExplanation:', { selectedExplanation, isExplanationModalOpen });
  
    setSelectedExplanation(optionId);
    setIsExplanationModalOpen(true);
  
    console.log('After toggleExplanation:', { selectedExplanation, isExplanationModalOpen });
  };


  return (
    <div className={`product-product ${isHoverDisabled ? 'hover-disabled' : ''}`} onClick={handleCustomizeClick}>
    {kampanje && <div className="promotion-overlay">Kampanje</div>}
    {pakke && <div className="pack-overlay">Pakke</div>}
    <img class="product-image" src={imagePath} alt={productName} />
      <div className='product-bottom'>
        <div className='product-name'>{productName}</div>
        <div className='product-price'>Fra {price} Nok</div>
      </div>
    
      {isModalOpen && (
  <Modal onClose={handleModalClose} image={imagePath} totalPrice={totalPrice}>
    <h1>{productName}</h1>
    <p>{beskrivelse}</p>
    <p><i>Trykk på spørsmålene hvis du ønsker mer info</i></p>

    <img src={imagePath} alt={productName} style={{ maxWidth: '100%', maxHeight: '200px' }} />
    <div className="product-customization-options-container">
      {Object.keys(options).map((optionId) => (
        <div key={optionId}>
          <div className="option-container">
            <label htmlFor={`${optionId}-${id}`} onClick={() => toggleExplanation(optionId)}>
              {options[optionId].question}
              {options[optionId].explanation && (
                <span></span>
              )}
            </label>
            {showExplanation[optionId] && selectedExplanation === optionId && (
              <p className="explanation">{options[optionId].explanation}</p>
            )}
          </div>
          <select
            id={`${optionId}-${id}`}
            value={selectedOptions[optionId] || '0'}
            onChange={(e) =>
              setSelectedOptions({
                ...selectedOptions,
                [optionId]: e.target.value,
              })
            }
          >
            <option value="0" disabled hidden>
              Velg et alternativ
            </option>
            {options[optionId].alternatives.map((alternative) => (
              <option key={alternative.alternative} value={alternative.alternative}>
                {alternative.alternative} (+{alternative.price} nok)
              </option>
            ))}
          </select>
        </div>
      ))}

    </div>
    <div className="total-price-leggtil">Total pris: {totalPrice} nok</div>
    <button className='product-button' onClick={handleAddToCartClick}>Legg til i handlekurv</button>
  </Modal>
)}

{isExplanationModalOpen && (
        <Modal onClose={() => setIsExplanationModalOpen(false)}>
          {selectedExplanation && (
            <div>
              <h2>Forklaring</h2>
      
              {options[selectedExplanation].explanationImage && (
                <img
                  src={`https://www.bbelektroapi.shop/bilder/${options[selectedExplanation].explanationImage}`}
                  alt={`Product: ${productName}`}
                  style={{ maxWidth: '100%', maxHeight: '200px' }} 
                />
              )}
                      <p className="explanation">{options[selectedExplanation].explanation}</p>
              <button onClick={() => setIsExplanationModalOpen(false)}>Lukk Forklaring</button>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default Product;
