import React, { useState, useEffect } from 'react';
import './Nettbutikk.css';
import Product from './product';

const fetchData = async (url) => {
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

const Nettbutikk = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await fetchData('https://www.bbelektroapi.shop/products');
      setAllProducts(data);
      setFilteredProducts(data);
    };

    fetchProducts();

    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    // Filter products locally based on the search term
    const filtered = allProducts.filter(product =>
      product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchTerm, allProducts]);

  const handleAddToCart = (cartItem) => {
    setCart((prevCart) => [...prevCart, cartItem]);
  };

  return (
    <div className="nettbutikk-nettbutikk">

  
      <div className="search-bar">
        <input
          type="text"
          placeholder="Søk etter produkter..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
  
      <div className="nettbutikk-product-list">
        {filteredProducts.map((product) => (
          <Product
            key={product.id}
            product={product}
            onAddToCart={handleAddToCart}
            
          />
        ))}
      </div>
    </div>
  );
};

export default Nettbutikk;
