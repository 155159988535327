// Header.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiShoppingBag } from "react-icons/fi";
import { BiStore } from "react-icons/bi";
import { PiVanBold } from "react-icons/pi";
import { BiHomeAlt } from "react-icons/bi";
import { BiSupport } from "react-icons/bi";
import Logo from './iywcqd5x0otfnstza1zs.png';
import nettbutikklogo from './Nettbutikk.png';

import './Header.css';

const Header = ({ cartItemsCount, cartTotalPrice }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    // Set initial mobile state
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      {isMobile ? (
        <div className={`mobile-header ${isMenuOpen ? 'open' : ''}`}>
          <div className="menu-toggle" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Link to="/" onClick={closeMenu}>
            <img className="mobile-logo" src={Logo} alt="Logo" />
            <img className="mobile-nettbutikklogo" src={nettbutikklogo} alt="nettbutikklogo" />
          </Link>
          
          <Link to="/Handlekurv" className="cart-button-mobile" onClick={closeMenu}>
            <FiShoppingBag size={20} />
            <span className="mobile-cart-count">{cartItemsCount}</span>
            <span className="mobile-cart-total-price">{cartTotalPrice}</span>
          </Link>
          <div className={`mobile-links ${isMenuOpen ? 'open' : ''}`}>
            <Link to="/" onClick={closeMenu}>Home</Link>
            <Link to="/nettbutikk" onClick={closeMenu}>Nettbutikk</Link>
            <Link to="/Prosess" onClick={closeMenu}>Prosess</Link>
            <Link to="/Kontakt" onClick={closeMenu}>Kontakt oss</Link>
          </div>
        </div>
      ) : (
        <div className="header">
          <div className="header-container">
            <div className="logo-container">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className="nav-buttons">


            <Link to="/" className="cart-button">
                <BiHomeAlt  size={20} />
                <span className="text-under-icon">Hjem</span>
                </Link>


              <Link to="/nettbutikk" className="cart-button">
                <BiStore size={20} />
                <span className="text-under-icon">Nettbutikk</span>
                </Link>



              <Link to="/Prosess" className="cart-button">
                <PiVanBold size={20}  />
                <span className="text-under-icon">Prosess</span>
                </Link>



              <Link to="/Kontakt" className="cart-button">
                <BiSupport size={20}/> 
                 <span className="text-under-icon">Kontakt</span>
            
              </Link>



              <Link to="/Handlekurv" className="cart-button">
                
              <span className="cart-count">{cartItemsCount}</span>

                <FiShoppingBag size={20} />

            <span className="cart-total-price">{cartTotalPrice}</span>

              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
