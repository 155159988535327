// LoginForm.js

import React, { useState } from 'react';
import './LoginForm.css'; // Import the CSS file

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await fetch('https://www.bbelektroapi.shop/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const { token } = await response.json();
        onLogin(token);
      } else {
        alert('Invalid username or password');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="login-form-container">
      <div className="login-form-wrapper">
        <h2 className="login-form-heading">Logg inn for eier</h2>
      <label className="login-form-label">Username: </label>
      <input
        className="login-form-input"
        type="text"
        placeholder="Brukernavn"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <br />
      <label className="login-form-label">Password: </label>
      <input
        className="login-form-input"
        type="password"
        placeholder="Passord"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <button className="login-form-button" onClick={handleLogin}>
        Login
      </button>

      </div>    </div>
  );
};

export default LoginForm;

