import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';


import './Cart.css'; // Import the updated CSS file with the new styling

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
    // eslint-disable-next-line
  const [totalAmount, setTotalAmount] = useState(0);
  const [customerInfo, setCustomerInfo] = useState({
    fullName: '',
    address: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
  const [isMessageSent, setIsMessageSent] = useState(false); // New state for tracking message send status



  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCart);
  }, []);

  useEffect(() => {
    const total = cartItems.reduce((acc, item) => acc + item.totalPrice, 0);
    setTotalAmount(parseFloat(total).toFixed(2));
  }, [cartItems]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  
  const handleOrder = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    // Anta at cartItems allerede inneholder detaljert informasjon om hvert produkt,
    // inkludert valgte alternativer og totalpris.
    // Du må kanskje tilpasse dette avhengig av hvordan cartItems er strukturert.
    const orderDetails = cartItems.map(item => ({
      productName: item.productName,
      quantity: item.selectedQuantity,
      selectedOptions: item.optionDetails.map(option => ({
        question: option.question,
        answer: option.answer,
        price: option.price
      })),
      totalPrice: item.totalPrice
    }));
  
    const orderData = {
      ...customerInfo,
      orderDetails
    };
  
    console.log('Sending order data:', orderData);
    // Send ordredetaljer sammen med kundeinformasjon til serveren
    fetch('https://www.bbelektroapi.shop/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    })
    .then(response => response.json())
    .then(data => {
      setIsLoading(false); // Stop loading
      console.log('Success:', data);
      setIsMessageSent(true); // Marker melding som sendt
      setCartItems([]);
      localStorage.removeItem('cart'); // Remove cart from local storage
    })
    .catch((error) => {
      setIsLoading(false); // Stop loading
      console.error('Error:', error);
    });
  };
  
  
  
  // eslint-disable-next-line
  const handleEditQuantity = (itemId, newQuantity) => {
    const updatedCart = cartItems.map((item) =>
      item.id === itemId
        ? { ...item, selectedQuantity: newQuantity, totalPrice: item.totalWithOptions * newQuantity }
        : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleRemoveItem = (itemId) => {
    const updatedCart = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };
  // eslint-disable-next-line
  const handleSubtractItem = (itemId) => {
    const updatedCart = cartItems.map((item) =>
      item.id === itemId && item.selectedQuantity > 0
        ? { ...item, selectedQuantity: item.selectedQuantity - 1, totalPrice: item.totalWithOptions * (item.selectedQuantity - 1) }
        : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };
  // eslint-disable-next-line
  const handleAddItem = (itemId) => {
    const updatedCart = cartItems.map((item) =>
      item.id === itemId
        ? { ...item, selectedQuantity: item.selectedQuantity + 1, totalPrice: item.totalWithOptions * (item.selectedQuantity + 1) }
        : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  return (
    <div className="cart-container">
          {isLoading &&    
    <div className="loading-overlay">
    <TailSpin height="80" width="80" color="#3498db" ariaLabel="loading-indicator" />
  </div>
     }
      {cartItems.length > 0 ? (
cartItems.map((item) => (
  <div key={item.id} className="cart-item">
    <img src={item.image} alt={item.productName} className="cart-item-image"/>
    <div className="item-details">
      <p className="product-name-cart">{item.productName}</p>
      {item.optionDetails.map((option) => (
        <div key={option.optionId} className="option-detail-cart">
          <strong>{option.question}: </strong>{option.answer} ({parseFloat(option.price).toFixed(2)}  nok)
        </div>
      ))}
    </div>
    <div className="price-display-cart">
      <div className="remove-button-cart" onClick={() => handleRemoveItem(item.id)}>TA BORT</div>
      <div className="price-per-piece-cart">{parseFloat(item.totalWithOptions).toFixed(2)} nok</div>
     
    </div>
  </div>
))
        
      ) : (
        <p>handlekurven er tom</p>
        
      )}
 {totalAmount >= 1 && (
      <div className="total-price-line">
        Total pris: {parseFloat(totalAmount).toFixed(2)} nok
      </div>
    )}

      <form className="customer-info-form" onSubmit={handleOrder}>
        <div className="form-row">
          <label htmlFor="fullName">Fult Navn:</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Fult Navn"
            value={customerInfo.fullName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="address">Adresse:</label>
          <input
            type="text"
            id="address"
            name="address"
            placeholder="Adresse"
            value={customerInfo.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="postalCode">Postnummer:</label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            placeholder="Postnummer"
            value={customerInfo.postalCode}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="phoneNumber">Telefon nummer:</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Telefon nummer"
            value={customerInfo.phoneNumber}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="email">Epost:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Epost"
            value={customerInfo.email}
            onChange={handleInputChange}
          />
        </div>
        <button 
          type="submit" 
          className="order-button" 
          disabled={isLoading || isMessageSent} // Disable button when loading or message has been sent
        >
          {isMessageSent ? "Forespørselen er sendt!" : "Send forespørsel"} 
        </button>
      </form>
      
      <div className="order-text">Elektriker kontakter deg innen 48 timer for booking av avtale, faktura kommer etter utført arbeid</div>
    </div>
  );
};

export default Cart;