import React from 'react';
import './Prosess.css';
import { useNavigate } from 'react-router-dom';

const Prosess = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Du kan legge til ønsket funksjonalitet her, for eksempel en bestillingshandling.
    // For nå vil vi bare navigere til en annen side når brukeren klikker på "Legg inn bestilling".
    navigate('/nettbutikk');
  };

  return (
    <div className="Prosess-container">
      <div className="Prosess-box Prosess-step">
        <h2>Trinn 1.</h2>
        <p>Legg inn en bestilling.</p>
        <button onClick={handleClick}>Legg inn bestilling</button>
      </div>

      <div className="Prosess-box Prosess-step">
        <h2>Trinn 2.</h2>
        <p>En av våre medarbeidere kontakter deg i løpet av 2 virkedager for å avtale tid for utføring.</p>
      </div>

      <div className="Prosess-box Prosess-step">
        <h2>Trinn 3.</h2>
        <p>Elektriker kommer og utfører arbeidet som avtalt.</p>
      </div>

      <div className="Prosess-box Prosess-step">
        <h2>Trinn 4.</h2>
        <p>Alt av dokumentasjon samt faktura kommer på mail.</p>
      </div>
    </div>
  );
};

export default Prosess;
