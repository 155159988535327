import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './Kontakt.css';

const Kontakt = () => {
  // eslint-disable-next-line
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., send data to server

    // Open the modal
    setModalIsOpen(true);
  };

  const closeModal = () => {
    // Close the modal and reset form data
    setModalIsOpen(false);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  return (
    <div className="kontakt-container">
      {!modalIsOpen ? (
        <>
          <h2>Kontakt oss</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Navn:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="email">E-post:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="subject">Emne:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="message">Melding:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>

            <button type="submit" className="kontakt-button">
              Send
            </button>
          </form>
        </>
      ) : (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Takk Modal"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>Takk!</h2>
          <p>Kontaktinformasjon er sendt.</p>
          <p>Du vil få tilbakemelding innen 2 virkedager.</p>
          <button onClick={closeModal} className="kontakt-button">
            Lukk
          </button>
        </Modal>
      )}
      <div className="contact-info">
        <h3>Kontaktinformasjon</h3>
        <p>Firmanavn: BBElektro AS</p>
        <p>Organisasjonsnummer: 123456789</p>
        <p>E-post: kontakt@bbelektro.no</p>
        <p>Telefon: +47 123 45 678</p>
      </div>
    </div>
  );
};

export default Kontakt;
