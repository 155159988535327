import React, { useState, useEffect } from 'react';
import './Kontrollpanel.css';
import axios from 'axios'; // Import axios for HTTP requests

const Kontrollpanel = () => {
  const [updatedProducts, setUpdatedProducts] = useState([]); // State for modified products
  const [isLoading, setIsLoading] = useState(true);
  

  useEffect(() => {
    axios.get('https://www.bbelektroapi.shop/products.json')
      .then((response) => {
        setUpdatedProducts(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setIsLoading(false);
      });
  }, []);

  const handleSaveChanges = () => {
    axios.put('https://www.bbelektroapi.shop/products', updatedProducts)
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error('Error updating products:', error);
      });
  };
  const handleOptionChange = (productId, optionId, selectedAlternative) => {
    const updated = updatedProducts.map((product) => {
      if (product.id === productId) {
        const selectedOptions = { ...product.selectedOptions, [optionId]: selectedAlternative };
        return { ...product, selectedOptions };
      }
      return product;
    });
    setUpdatedProducts(updated);
  };
  
// eslint-disable-next-line
  const handleProductNameChange = (productId, newName) => {
    // Update product name in state
    const updated = updatedProducts.map((product) =>
      (product.id === productId ? { ...product, productName: newName } : product)
    );
    setUpdatedProducts(updated);
  };


  return (
    <div>
      <h2>Kontrollpanel</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
{updatedProducts.map((product) => (
  <div key={product.id}>
    <h3>{product.productName}</h3>
    {product.options && Object.keys(product.options).map((optionId) => (
      <div key={optionId}>
        <h4>{product.options[optionId]?.question}</h4>
        <select
          value={product.selectedOptions?.[optionId]}
          onChange={(e) => handleOptionChange(product.id, optionId, e.target.value)}
        >
          {product.options[optionId]?.alternatives.map((alternative) => (
            <option key={alternative.alternative} value={alternative.alternative}>
              {alternative.alternative} ({alternative.price} NOK)
            </option>
          ))}
        </select>
      </div>
    ))}
  </div>
))}

          <button onClick={handleSaveChanges}>Save Changes</button>
        </div>
      )}
    </div>
  );
};

export default Kontrollpanel;
