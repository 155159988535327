// AdminPanel.js
import React, { useState, useEffect } from 'react';
import './AdminPanel.css';
import LoginForm from './LoginForm';
const AdminPanel = () => {
    const [products, setProducts] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('token') || ''); // Check for token in localStorage
    const [isLoggedIn, setIsLoggedIn] = useState(!!token); // Set isLoggedIn based on the presence of a token

    const [newProduct, setNewProduct] = useState({
        productName: '',
        price: 0,
        beskrivelse: '',
        image: '',
        explanationImages: [], // New field for explanation images
        options: {},
        kampanje: false, // New property for kampanje
        pakke: false, // New property for kampanje
    });
    const [isKampanje, setIsKampanje] = useState(false); // New state for the checkbox
    const [isPakke, setIsPakke] = useState(false); // New state for the checkbox

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // Your existing fetchData logic
    
        // Check if there's a valid token, and set login status accordingly
        if (token) {
          setIsLoggedIn(true);
        }
      }, [token]);

      const handleLogin = (newToken) => {
        setToken(newToken);
        setIsLoggedIn(true);
        localStorage.setItem('token', newToken); // Save token to localStorage
      };
    
      const handleLogout = () => {
        setToken('');
        setIsLoggedIn(false);
        localStorage.removeItem('token'); // Remove token from localStorage
        // Additional logout logic if needed
      };


    const fetchData = async () => {
        try {
            const response = await fetch('https://www.bbelektroapi.shop/products');
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Feil ved henting av produkter:', error);
        }
    };

    const handleAddProduct = async () => {
        const hasEmptyAlternative = Object.values(newProduct.options).some((option) =>
            option.alternatives.some((alternative) => alternative.alternative === '')
        );

        if (hasEmptyAlternative) {
            console.error('Alternatives cannot be empty. Please fill in all alternatives.');
            return;
        }

        const optionsArray = Object.values(newProduct.options);
        try {
            const token = localStorage.getItem('token');
            console.log('Token sent to server for add product:', token); // Add this line to log the token
            const response = await fetch('https://www.bbelektroapi.shop/registrerProdukt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token in the header
                },
                body: JSON.stringify({
                    ...newProduct,
                    kampanje: isKampanje,
                    pakke: isPakke,
                    options: optionsArray,
                    explanationImages: Object.values(newProduct.options).map(option => option.explanationImage),
                }),
            });
            if (response.ok) {
                console.log('Produkt lagt til!');
                fetchData();
            } else {
                console.error('Feil ved lagring av produkt.');
            }
        } catch (error) {
            console.error('Feil ved lagring av produkt:', error);
        }
    };

    const handleDeleteProduct = async (productId) => {
        const token = localStorage.getItem('token');
        console.log('Token sent to server for delete:', token); // Add this line to log the token
    
        try {
            const response = await fetch(`https://www.bbelektroapi.shop/deleteProduct/${productId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            if (response.ok) {
                console.log('Produkt slettet!');
                fetchData();
            } else {
                console.error('Feil ved sletting av produkt.');
            }
        } catch (error) {
            console.error('Feil ved sletting av produkt:', error);
        }
    };

    const handleExplanationImageDrop = async (e, optionId) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;

        if (droppedFiles.length > 0) {
            const file = droppedFiles[0];
            const formData = new FormData();
            formData.append('image', file);

            try {
                const response = await fetch('https://www.bbelektroapi.shop/uploadImage', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Forklaring bilde opplastet:', data.imagePath);

                    setNewProduct((prevProduct) => ({
                        ...prevProduct,
                        options: {
                            ...prevProduct.options,
                            [optionId]: {
                                ...prevProduct.options[optionId],
                                explanationImage: data.imagePath,
                            },
                        },
                    }));
                } else {
                    console.error('Feil ved opplasting av forklaring bilde.');
                }
            } catch (error) {
                console.error('Feil ved opplasting av forklaring bilde:', error);
            }
        }
    };

    const handleAddOption = (optionId) => {
        setNewProduct((prevProduct) => {
            const currentOption = prevProduct.options[optionId];
            const newAlternatives = [
                ...currentOption.alternatives,
                { alternative: '', price: 0 },
            ];

            const updatedOptions = {
                ...prevProduct.options,
                [optionId]: {
                    ...currentOption,
                    alternatives: newAlternatives,
                },
            };

            return {
                ...prevProduct,
                options: updatedOptions,
            };
        });
    };

    const handleRemoveOption = (optionId, alternativeIndex) => {
        setNewProduct((prevProduct) => {
            const updatedAlternatives = prevProduct.options[optionId].alternatives.filter(
                (_, index) => index !== alternativeIndex
            );
            return {
                ...prevProduct,
                options: {
                    ...prevProduct.options,
                    [optionId]: {
                        ...prevProduct.options[optionId],
                        alternatives: updatedAlternatives,
                    },
                },
            };
        });
    };

    const handleAddQuestion = () => {
        const newQuestionId = Object.keys(newProduct.options).length + 1;
        setNewProduct((prevProduct) => ({
            ...prevProduct,
            options: {
                ...prevProduct.options,
                [newQuestionId]: {
                    question: '',
                    explanation: '',
                    alternatives: [{ alternative: '', price: 0 }],
                },
            },
        }));
    };

    const handleRemoveQuestion = (questionId) => {
        const { [questionId]: removedQuestion, ...restOptions } = newProduct.options;
        setNewProduct((prevProduct) => ({
            ...prevProduct,
            options: restOptions,
        }));
    };


    const handleImageDrop = async (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;

        if (droppedFiles.length > 0) {
            const file = droppedFiles[0];
            const formData = new FormData();
            formData.append('image', file);

            // Log the FormData to check if the file is appended correctly
            console.log('FormData:', formData);

            try {
                const response = await fetch('https://www.bbelektroapi.shop/uploadImage', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Bilde opplastet:', data.imagePath);

                    setNewProduct((prevProduct) => ({
                        ...prevProduct,
                        image: data.imagePath,
                    }));
                } else {
                    console.error('Feil ved opplasting av bilde.');
                }
            } catch (error) {
                console.error('Feil ved opplasting av bilde:', error);
            }
        }
    };

    return (
        <div className="AdminPanel">
        {isLoggedIn ? (
            <>
            <h2 className="AdminPanel-heading">Produkt meny</h2>

            <div>
                <h3 className="AdminPanel-heading">Legg til nytt produkt</h3>
                <label className="AdminPanel-label">Er dette en pakke? </label>
                <input
                    type="checkbox"
                    checked={isPakke}
                    onChange={() => setIsPakke(!isPakke)}
                />
                <label className="AdminPanel-label">Er dette en kampanje? </label>
                <input
                    type="checkbox"
                    checked={isKampanje}
                    onChange={() => setIsKampanje(!isKampanje)}
                />
                <label className="AdminPanel-label">Produktnavn: </label>
                <input
                    className="AdminPanel-input"
                    type="text"
                    value={newProduct.productName}
                    onChange={(e) => setNewProduct({ ...newProduct, productName: e.target.value })}
                />
                       <label className="AdminPanel-label">Produktbeskrivelse: </label>
                <input
                    className="AdminPanel-input"
                    type="text"
                    value={newProduct.beskrivelse}
                    onChange={(e) => setNewProduct({ ...newProduct, beskrivelse: e.target.value })}
                />
                <label className="AdminPanel-label">Pris: </label>
                <input
                    className="AdminPanel-input"
                    type="number"
                    value={newProduct.price}
                    onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                />

                <input
                    className="AdminPanel-input"
                    type="text"
                    value={newProduct.image}
                    readOnly // Make the input read-only since it's not intended for direct user input
                    placeholder="Dra bildet inn her for å benytte det"
                    onDrop={handleImageDrop} // Legg til onDrop-hendelse for å håndtere bildeopplasting
                    onDragOver={(e) => e.preventDefault()} // Forebygg standard oppførsel under dra og slipp
                />

                {Object.keys(newProduct.options).map((optionId) => (
                    <div key={optionId}>
                        <h4 className="AdminPanel-heading">
                            Spørsmål {optionId}
                            <button className="AdminPanel-button" onClick={() => handleRemoveQuestion(optionId)}>Fjern spørsmål</button>
                        </h4>
                        <label className="AdminPanel-label">Spørsmål: </label>
                        <input
                            className="AdminPanel-input"
                            type="text"
                            value={newProduct.options[optionId].question}
                            onChange={(e) =>
                                setNewProduct((prevProduct) => ({
                                    ...prevProduct,
                                    options: {
                                        ...prevProduct.options,
                                        [optionId]: { ...prevProduct.options[optionId], question: e.target.value },
                                    },
                                }))
                            }
                        />
                        <label className="AdminPanel-label">Forklaring: </label>
                        <input
                            className="AdminPanel-input"
                            type="text"
                            value={newProduct.options[optionId].explanation}
                            onChange={(e) =>
                                setNewProduct((prevProduct) => ({
                                    ...prevProduct,
                                    options: {
                                        ...prevProduct.options,
                                        [optionId]: { ...prevProduct.options[optionId], explanation: e.target.value },
                                    },
                                }))
                            }
                        />
                        <label className="AdminPanel-label">Bilde for forklaring: </label>
                        <input
                            className="AdminPanel-input"
                            type="text"
                            value={newProduct.options[optionId].explanationImage}
                            readOnly
                            placeholder="Dra bildet inn her for å benytte det"
                            onDrop={(e) => handleExplanationImageDrop(e, optionId)}
                            onDragOver={(e) => e.preventDefault()}
                        />
                        <h5 className="AdminPanel-heading">Alternativer</h5>
                        {newProduct.options[optionId].alternatives.map((alternative, alternativeIndex) => (
                            <div key={alternativeIndex}>
                                <label className="AdminPanel-label">Alternativ {alternativeIndex + 1}: </label>
                                <input
                                    className="AdminPanel-input"
                                    type="text"
                                    value={alternative.alternative}
                                    onChange={(e) =>
                                        setNewProduct((prevProduct) => ({
                                            ...prevProduct,
                                            options: {
                                                ...prevProduct.options,
                                                [optionId]: {
                                                    ...prevProduct.options[optionId],
                                                    alternatives: prevProduct.options[optionId].alternatives.map(
                                                        (alt, index) =>
                                                            index === alternativeIndex
                                                                ? { ...alt, alternative: e.target.value }
                                                                : alt
                                                    ),
                                                },
                                            },
                                        }))
                                    }
                                />
                                <label className="AdminPanel-label">Pris: </label>
                                <input
                                    className="AdminPanel-input"
                                    type="number"
                                    value={alternative.price}
                                    onChange={(e) =>
                                        setNewProduct((prevProduct) => ({
                                            ...prevProduct,
                                            options: {
                                                ...prevProduct.options,
                                                [optionId]: {
                                                    ...prevProduct.options[optionId],
                                                    alternatives: prevProduct.options[optionId].alternatives.map(
                                                        (alt, index) =>
                                                            index === alternativeIndex
                                                                ? { ...alt, price: e.target.value }
                                                                : alt
                                                    ),
                                                },
                                            },
                                        }))
                                    }
                                />
                                <button className="AdminPanel-button" onClick={() => handleRemoveOption(optionId, alternativeIndex)}>
                                    Fjern alternativ
                                </button>
                            </div>
                        ))}
                        <button className="AdminPanel-button" onClick={() => handleAddOption(optionId)}>Legg til alternativ</button>
                    </div>
                ))}
                <button className="AdminPanel-button" onClick={handleAddQuestion}>Legg til spørsmål</button>
                <button className="AdminPanel-button" onClick={handleAddProduct}>Legg til produkt</button>
            </div>

            <div>
                <h3 className="AdminPanel-heading">Eksisterende produkter</h3>
                <ul className="AdminPanel-list">
                    {products.map((product) => (
                        <li className="AdminPanel-list-item" key={product._id}>
                            {product.productName} - {product.price}
                            <button className="AdminPanel-button" onClick={() => handleDeleteProduct(product._id)}>Slett</button>
                        </li>
                    ))}<button className="AdminPanel-logout-button" onClick={handleLogout}>Logout</button>
                 
                
                </ul>
            </div>
            </>
            ) : (
                <LoginForm onLogin={handleLogin} />
            )}
        </div>
    );
};

export default AdminPanel;
