import React, { useState, useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import './Forside.css';

const Forside = () => {
  const navigate = useNavigate();

  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const container = document.querySelector('.slide-container');
      if (container) {
        const width = Math.min(container.offsetWidth, 1064); // Limit to max 800px
        setContainerWidth(width);
      }
    };


    handleResize(); // Initial calculation
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const slideImages = [

   
    {
      url: 'https://www.bbelektroapi.shop/bilder/702790_02.jpg',
    },
    {

      url: 'https://www.bbelektroapi.shop/bilder/702360_03.jpg',
    },
    {
      url: 'https://www.bbelektroapi.shop/bilder/interior.jpg',
    },
    {
      url: 'https://www.bbelektroapi.shop/bilder/mill-varmeovn-glass-panelovn-1200x900-1.jpg',
    },
    {
      url: 'https://www.bbelektroapi.shop/bilder/outdoor.jpg',
    },
    {
      url: 'https://www.bbelektroapi.shop/bilder/zaptecgo-1-scaled.jpg',
    },

  ];
  const handleSignUp = () => {// eslint-disable-line no-unused-vars
    navigate('/success'); // Replace '/success' with the desired route
  };

  const spanStyle = {
    position: 'absolute',
    top: '80%', // Adjust the positioning as needed
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    minWidth: `${containerWidth }px`, // Set minimum height to half of the width

    color: '#000000',
    background: 'rgba(255, 255, 255, 0.7)', // eslint-disable-line no-unused-vars
    textAlign: 'center',
    zIndex: '2', // Ensure the span appears above the images
  };

  return (
    <div className="forside">
      <div className="slide-container" style={{ maxWidth: '1064px', position: 'relative' }}>
        <span style={spanStyle}>
          <div className='forside-big-font'>
        Vi i BBElektro  gjør det trygt og enkelt for våre kunder.</div>
        <div className='forside-small-font'>
          Ved å yte god personlig service, levere høy kvalitet på våre arbeider, og ha markedets beste garantiordning.</div>
        </span>
        <Slide>
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  minHeight: `${containerWidth / 1.5}px`, // Set minimum height to half of the width
                  backgroundImage: `url(${slideImage.url})`,
                }}
              ></div>
            </div>
          ))}
        </Slide>
      </div>
    </div>
  );
};

export default Forside;