

import Forside from './Forside';
import Kontakt from './Kontakt';
import Header from './Header';
import React, { useState, useEffect } from 'react';
import Nettbutikk from './Nettbutikk';
import Prosess from './Prosess';
import Kontrollpanel from './Kontrollpanel';
import AdminPanel from './AdminPanel';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Cart from './Cart';

function App() {
  // Initialize cart in localStorage
  if (!localStorage.getItem('cart')) {
    localStorage.setItem('cart', JSON.stringify([]));
  }

  const [cart, setCart] = useState(() => {  // eslint-disable-line no-unused-vars
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const cartItemsCount = cart.length;

  const cartTotalPrice = cart.reduce((total, item) => total + item.totalPrice, 0).toLocaleString('nb-NO', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  
  const updateCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem('cart');
    setCart(savedCart ? JSON.parse(savedCart) : []);
  };

  useEffect(() => {
    // Update the cart from localStorage on mount
    updateCartFromLocalStorage();

    // Set up interval to update the cart every 5 seconds
    const intervalId = setInterval(updateCartFromLocalStorage, 1000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only on mount and unmount


  return (
    <div className="App">
      <BrowserRouter>
      <Header cartItemsCount={cartItemsCount} cartTotalPrice={cartTotalPrice} />
        <Routes>
          <Route path="/" element={<Forside />} />
          <Route path="/Kontakt" element={<Kontakt />} />
          <Route path="/Nettbutikk" element={<Nettbutikk />} />
          <Route path="/Kontrollpanel" element={<Kontrollpanel />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/Prosess" element={<Prosess />} />

          <Route path="/handlekurv" element={<Cart />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
